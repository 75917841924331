$heading-partner-color: #333333 !default;

$color-primary-brand-dark: #000082 !default;
$color-primary-brand-light: #006ff1 !default;
$color-secondary-accent-1: #ff474a !default;
$color-secondary-accent-2: #83eece !default;
$color-secondary-dark-accessible: #0c112a !default;
$color-secondary-light-accessible-8: #ebf4ff !default;
$color-secondary-light-accessible-20: #cce2fc !default;

$brand-link-partner-color: $color-primary-brand-light !default;
$sidebar-icon-partner-color: $color-primary-brand-light !default;
$progress-bar-partner-color: $color-primary-brand-light !default;
$visual-picker-active-border-partner-color: $color-primary-brand-light !default;
$progress-bar-icon-partner-color: $color-primary-brand-light !default;
$highlighted-border-drop-zone-partner-color: $color-primary-brand-light !default;
$uploaded-file-description-partner-color: $color-primary-brand-light !default;
$input-radio-group-checked-partner-color: $color-primary-brand-dark !default;
$input-checkbox-selected-partner-color: $color-primary-brand-dark !default;
$button-with-icon-text-partner-color: $color-primary-brand-dark !default;
$button-with-icon-hover-text-partner-color: $color-secondary-dark-accessible !default;
$spinner-active-border-partner-color: $color-primary-brand-dark !default;
$application-header-dropdown-name-color: $color-primary-brand-dark !default;
